<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="content_margin_bottom">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h4 class="card-title my-3 float-right">
            <router-link
              :to="`/entity/${entityId}/courses`"
              class="btn btn-success btn-sm"
              >Courses</router-link
            >
          </h4>
        </div>
      </div>
      <section>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label for="unit_name">Title</label>
                  <input
                    type="text"
                    placeholder="Enter Test Title"
                    id="unit_name"
                    class="form-control"
                    v-model="test.name"
                  />
                </div>
                <div class="form-group">
                  <label for="pass_score">Pass Score</label>
                  <input
                    type="number"
                    placeholder="Enter Pass Score"
                    id="pass_score"
                    class="form-control"
                    v-model="test.pass_score"
                  />
                </div>
                <div class="form-group">
                  <label for="max_attempts">Maximum Number of Attempts</label>
                  <input
                    type="number"
                    placeholder=" Maximum Number of Attempts"
                    class="form-control"
                    v-model="test.max_attempts"
                  />
                </div>
                <div class="form-group">
                  <label for="max_attempts">Test Description</label>
                  <input
                    type="text"
                    placeholder="Description"
                    class="form-control"
                    v-model="test.description"
                  />
                </div>
                <div class="form-group">
                  <label for="duration">Duration in seconds</label>
                  <input
                    type="number"
                    placeholder="Duration in seconds"
                    class="form-control"
                    v-model="test.duration"
                  />
                </div>
                <div v-if="showProduct">
                  <div v-for="(res, i) in newInput" :key="`input-${i}`">
                    <div>
                      <div class="form-group p-2">
                        <label for="product">
                          <span class="font-weight-bolder">{{
                            i == 0 ? i + 1 : i + 1
                          }}</span
                          >. Question</label
                        >
                        <textarea
                          type="text"
                          placeholder="Enter Question"
                          id="price"
                          class="form-control"
                          v-model="res.ques"
                        />
                      </div>

                      <div class="form-group p-2">
                        <label for="weight">Mark per question</label>
                        <input
                          type="number"
                          placeholder="Enter Weight"
                          id="weight"
                          class="form-control"
                          v-model="res.weight"
                        />
                      </div>
                      <div class="divide_section">
                        <div v-for="(answer, j) in res.answers" :key="j">
                          <div class="form-group p-2 m-0">
                            <label for="answer"> Choice</label>
                            <input
                              type="text"
                              placeholder="Enter Answer"
                              id="answer"
                              class="form-control"
                              v-model="answer.choice"
                            />
                          </div>
                          <div class="form-group d-flex m-3">
                            <div class="form-check form-check-inline">
                              <label for="inliner" class="m-0 pr-2"
                                >Correct Answer?</label
                              >
                              <input
                                class="form-check-input"
                                type="radio"
                                v-model="res.answer"
                                @change="correctAnswer(j, i)"
                                id="inliner"
                                :value="j"
                              />
                              <label class="form-check-label" for="inliner"
                                >Yes</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mb-4">
                      <div class="removeItem">
                        <i
                          @click="removeInputs(i, newInput)"
                          class="btn-outline-danger btn-sm"
                          >Delete Question</i
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-outline-success btn-sm"
                    @click="addInputs"
                  >
                    Add Question
                  </button>
                </div>
                <div class="row justify-content-end">
                  <div class="py-2 py-3">
                    <button
                      @click.prevent="addQuestion"
                      class="btn btn-primary btn-block my-btn float-right"
                      :disabled="!isContent"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import store from "@/state/store";
import Layout from "../../layout/index.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Content",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    PageHeader,
    Layout,
    // Multiselect,
  },
  computed: {
    isContent() {
      return (
        this.test.name &&
        this.test.pass_score &&
        this.test.max_attempts &&
        this.test.duration &&
        this.newInput.length >= 1
      );
    },
  },
  data() {
    return {
      title: "Add Test",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Add Test",
          active: true,
        },
      ],
      newInput: [],
      showProduct: false,
      options: ["True", "False"],
      entityId: this.$route.params.id,
      productId: null,
      error: null,
      success: null,
      loading: false,
      test: [],
      product: [],
      productDetails: null,
    };
  },
  methods: {
    correctAnswer(j, i) {
      this.newInput[i].answers.forEach((answer) =>
        answer.correct == "True"
          ? (answer.correct = "False")
          : (answer.correct = "False")
      );
      this.newInput[i].answers[j].correct = "True";
    },
    addInputs() {
      let questions = {
        ques: "",
        weight: "",
        answers: [
          {
            choice: "",
            correct: "False",
          },
          {
            choice: "",
            correct: "False",
          },
          {
            choice: "",
            correct: "False",
          },
          {
            choice: "",
            correct: "False",
          },
        ],
      };

      this.newInput.push(questions);
      this.showProduct = true;
    },
    removeInputs(index, field) {
      field.splice(index, 1);
    },
    getCorrect(id) {
      let correctChoice = this.newInput.questions;
    },
    addQuestion() {
      this.loading = true;
      this.error = null;
      this.success = null;

      const question = {
        unit_name: this.test.name,
        entity_id: parseInt(this.$route.params.id),
        course_id: parseInt(this.$route.params.courseId),
        pass_score: this.test.pass_score,
        max_num_attempts: this.test.max_attempts,
        description: this.test.description,
        duration_in_secs: this.test.duration,
        questions: this.newInput,
      };

      const data = {
        test: question,
        courseId: this.$route.params.courseId,
      };
      console.log(question);

      // DISPATCHING DATA TO THE BACKEND
      store
        .dispatch("contents/addNewTest", data)
        .then((response) => {
          if (response.status == 201) {
            console.log(response.data);
            this.loading = false;
            this.toast({ message: "Test Added to Course", type: "success" });
            this.$router.replace(
              `/entity/${this.$route.params.id}/course/${this.$route.params.courseId}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          if (err?.response?.data == undefined) {
            this.loading = false;
            this.toast({
              message:
                "Network error. Check your network connection and try again.",
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
label {
  color: #000;
}

@media (min-width: 768px) {
  .divide_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.removeItem {
  color: red;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 20px;
  width: 50% !important;
  justify-content: center;
}
.removeItem > i {
  font-size: 20px;
}

#multi {
  width: 100%;
}

#multi > div {
  margin: 0 20px 20px 20px;
  width: 100%;
}

.table-wrapper {
  background: #fff;
  padding: 20px 25px;
  margin: 30px 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title {
  padding-bottom: 15px;
  background: linear-gradient(40deg, #ff2020, #05ffa3);
  color: #fff;
  padding: 16px 30px;
  margin: -20px -25px 10px;
  border-radius: 1px 1px 0 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.my-btn {
  width: 150px;
}
fieldset {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
}
</style>
